import manualSubmit from './clr_manualSubmit.json';
import clearanceMonitor from './clr_clearanceMonitor.json';
import declareRule from './clr_temuDeclareRule.json'
import returnPackage from './clr_returnPackage.json'

export default {
  ...manualSubmit,
  ...clearanceMonitor,
  ...declareRule,
  ...returnPackage
};
