import axios from 'axios';
import type { AxiosInstance, CancelTokenSource } from 'axios';
import QS from 'qs';
import { get, includes, isEqual } from 'lodash-es';
import { store } from '@/store';
import { error } from '@/utils';
import router from '@/router';
import type { HttpDeleteType, HttpDownloadType, HttpGetType, HttpPostType } from './type';
import { saveAs } from 'file-saver';

let flag: boolean | number = false;

const debounce = (func: () => any, second: number) => {
  if (flag) clearTimeout(flag as number);
  flag = window.setTimeout(() => {
    func();
    flag = false;
  }, second);
};

const pendingRequest = new Map();

const resource: AxiosInstance = axios.create({
  baseURL: '/api',
  paramsSerializer: (params) => QS.stringify(params, { arrayFormat: 'brackets' }),
  timeout: 60000 * 10
});

resource.interceptors.request.use(
  (config) => {
    const requestAdminURL: boolean = includes(get(config, 'url'), 'authInfo');
    const cancelToken: CancelTokenSource = axios.CancelToken.source();
    config.cancelToken = cancelToken.token;
    if (!includes(config.url, '/mts/i18n/app/OMS')) pendingRequest.set(config.url, cancelToken);
    if (config.headers) {
      // eslint-disable-next-line no-param-reassign
      config.headers.Authorization = `Bearer ${requestAdminURL ? store.state.adminToken : store.state.token}`;
    }
    return config;
  },
  (e) => {
    debounce(() => error(get(e, 'message')), 1000);
    return Promise.reject(e);
  }
);

resource.interceptors.response.use(
  (res) => {
    pendingRequest.delete(res.config.url);
    return res;
  },
  (e) => {
    if (e.message.includes('timeout')) {
      debounce(() => error('Request timeout,check you network or refresh'), 1000);
    }
    const { data, status, statusText, message } = e.response || {};
    if (status >= 400 && status < 600) {
      if (data) debounce(() => error(data.i18n || data.message), 1000);
      else debounce(() => error(statusText || message || 'Request Error'), 1000);
      // 对某些特定的状态码进行处理
      if (isEqual(status, 401)) {
        router.replace({ name: 'login' });
        store.commit('setToken', '');
      }
    }
    return Promise.reject(e);
  }
);

const cancelAllPendingRequest = () => {
  pendingRequest.forEach((cancelToken) => {
    cancelToken.cancel('Cancel all pending requests');
  });
  pendingRequest.clear();
};

export { cancelAllPendingRequest, resource };

const http: {
  get: HttpGetType;
  post: HttpPostType;
  delete: HttpDeleteType;
  put: HttpPostType;
  download: HttpDownloadType;
} = {
  async get(query, url, config) {
    try {
      const { data } = await resource.get(url, { params: query, ...config });
      return data;
    } catch (e) {
      return undefined;
    }
  },
  async post(query, url) {
    let result: 200 | 500 = 200;
    try {
      const { data } = await resource.post(url, query);
      result = data;
    } catch (e) {
      result = 500;
    }
    return result;
  },
  async put(query, url) {
    let result: 200 | 500 = 200;
    try {
      await resource.put(url, query);
    } catch (e) {
      result = 500;
    }
    return result;
  },
  async delete(url) {
    let result: 200 | 500 = 200;
    try {
      await resource.delete(url);
    } catch (e) {
      result = 500;
    }
    return result;
  },
  async download(fileName: string, url: string, { params = {}, methods = 'get' } = {}) {
    try {
      const { data, headers } = isEqual(methods, 'get')
        ? await resource.get(url, { params, responseType: 'arraybuffer' })
        : await resource.post(url, params, { responseType: 'arraybuffer' });
      const blob = new Blob([data], { type: headers['content-type'] });
      saveAs(blob, fileName);
    } catch (e) {
      console.log(e);
    }
  }
};

export default http;
