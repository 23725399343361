import { App } from 'vue';
import { formatSrc, mapKeyType } from '@/utils/modules/date.utils';
import { formatter } from '@/utils';

export default {
  /**
   * 为Vue全局应用插入字符串，时间，数组格式化的插件
   * @param app
   */
  install(app: App) {
    // eslint-disable-next-line no-param-reassign
    app.config.globalProperties.$formatter = (key: mapKeyType, date: formatSrc) => formatter.format(key, date);
  }
};
