import { get } from 'lodash-es';
import { PlaceholderCreatorType, RandomStringType, StringHashType } from '@/utils/type';
import i18n,{globalTranslate} from '@/i18n';

/**
 * 将字符串转为hash值
 * @param key 字符串
 * @returns
 */
const stringhash: StringHashType = (key) => {
  let hash = 5381;
  let i: any = get(key, 'length') || 0;
  while (i) {
    // eslint-disable-next-line no-bitwise
    hash = (hash * 33) ^ key.charCodeAt(--i);
  }
  // eslint-disable-next-line no-bitwise
  return hash >>> 0;
};

/**
 * 默认文本填充方法
 */
const usePlaceHolder = (): {
  pleaseInput: PlaceholderCreatorType;
  pleaseSelect: PlaceholderCreatorType;
} => {
  const pleaseInput: PlaceholderCreatorType = (value) => `${globalTranslate('placeholder.input')}${globalTranslate(value)}`;
  const pleaseSelect: PlaceholderCreatorType = (value) => `${globalTranslate('placeholder.select')}${globalTranslate(value)}`;
  return { pleaseInput, pleaseSelect };
};

/**
 * 返回制定长度的随机字符串
 * @param length
 * @returns
 */
const randomString: RandomStringType = (length) => {
  let s = '';
  Array.from({ length }).some(() => {
    s = `${s}${Math.random().toString(36).slice(2)}`;
    return s.length >= length;
  });
  return s.slice(0, length);
};

export { stringhash, usePlaceHolder, randomString };
