import systemVariables from './ssm_systemVariable.json';
import searchConfig from './ssm_searchConfig.json'
import questionSetting from './ssm_questionSetting.json'
import email from './ssm_email.json'

export default {
  ...systemVariables,
  ...searchConfig,
  ...email,
  ...questionSetting
};
