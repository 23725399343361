const systemVariableRoute = {
  path: '/ssm/systemVariable',
  component: () => import('@/views/ssm/systemVariable/SystemVariablePage.vue'),
  name: 'ssm.systemVariable'
};

const questionConfigRoute = {
  path: '/ssm/questionSetting',
  component: () => import('@/views/ssm/qesConfig/QesConfigPage.vue'),
  name: 'ssm.questionSetting'
};

const emailConfigRoute = {
  name: 'ssm.email',
  path: '/ssm/email',
  component: () => import('@/views/ssm/email/EmailConfigPage.vue')
};

const searchConfigRoute = {
  path: '/ssm/searchConfig',
  component: () => import('@/views/ssm/searchConfig/SearchConfigPage.vue'),
  name: 'ssm.searchConfig'
};

export default {
  name: 'ssm',
  component: () => import('@/layout/Layout.vue'),
  path: '/',
  children: [emailConfigRoute, questionConfigRoute, systemVariableRoute, searchConfigRoute]
};
