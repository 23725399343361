import fsmClientPushSetting from './fsm_clientPushSetting.json';
import traceStrategySetting from './fsm_traceStrategySetting.json';
import clientKpiSetting from './fsm_clientKpiSetting.json';
import fsmClientEventSetting from './fsm_clientEventSetting.json';

export default {
  ...fsmClientPushSetting,
  ...traceStrategySetting,
  ...fsmClientEventSetting,
  ...clientKpiSetting
};
