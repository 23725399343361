import { CalcPercentType } from '@/utils/type';

/**
 * 计算进度条百分比
 * @param num1
 * @param num2
 * @param point
 */
const calcPercent: CalcPercentType = (num1, num2, point) => parseFloat((((num1 || 0) / num2) * 100).toFixed(point));

export { calcPercent };
