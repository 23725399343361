import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, withKeys as _withKeys, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "comment__text basic-text__12" }
const _hoisted_2 = { class: "ml-8" }
const _hoisted_3 = { class: "flex-container-start pl-8 align-start" }

import { ref, watchEffect } from 'vue';
import { gte, size, split, compact, map, truncate, lte, invoke, slice, flow, trim } from 'lodash-es';
import { useI18n } from 'vue-i18n';
import { CloseOne } from '@icon-park/vue-next';
import { error } from '@/utils';


export default /*@__PURE__*/_defineComponent({
  __name: 'BatchEditor',
  props: {
    modelValue: {},
    limit: { default: 500 },
    borderColor: {},
    placeholder: {},
    type: {},
    disabled: { type: Boolean }
  },
  emits: ['update:modelValue', 'change'],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emits = __emit;
const ruleMap: Map<string, RegExp> = new Map([
  ['mawb', /^[0-9]{3}-[0-9]{8}$/],
  ['email', /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/]
]);
const inputList = ref<string[]>([]);
const inputData = ref('');
const focused = ref(false);
const input = ref(null);
const i18n = useI18n({ useScope: 'global' });
const spiltRegx = /,|\n|，|\s+/;
const errorMessage = ref('');
const formatValidate = (str: string) => {
  if (props.type) {
    if (ruleMap.get(props.type)?.test(str)) {
      return str;
    }
    errorMessage.value = `${i18n.t('err.format', {
      type: i18n.t(props.type)
    })}`;
  } else return str;
};
const isMultiple = () => {
  if (spiltRegx.test(inputData.value)) {
    inputList.value = inputList.value.concat(
      flow([(val) => map(val, (text) => trim(formatValidate(text))), compact])(
        split(inputData.value, spiltRegx, props.limit)
      )
    );
    if (gte(size(inputList.value), props.limit)) {
      error(i18n.t('limitSize', { size: props.limit }));
      inputList.value = slice(inputList.value, 0, props.limit);
    }
    if (errorMessage.value) error(errorMessage.value);
    return false;
  }
  return true;
};

const addItem = (event?: Event) => {
  errorMessage.value = '';
  invoke(event, 'preventDefault');
  const result = isMultiple();
  if (result && inputData.value) {
    if (formatValidate(inputData.value)) inputList.value.push(trim(inputData.value));
    else {
      error(errorMessage.value);
    }
  }
  inputData.value = '';
  emits('update:modelValue', inputList.value);
  emits('change');
};
const pasteData = (event: any) => {
  inputData.value = event.clipboardData.getData('text');
  addItem();
  setTimeout(() => {
    inputData.value = '';
  }, 0);
};
const deleteSelf = (index: number) => {
  inputList.value.splice(index, 1);
  emits('update:modelValue', inputList.value);
  emits('change');
};
watchEffect(() => {
  inputList.value = props.modelValue || [];
});

return (_ctx: any,_cache: any) => {
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_el_input = _resolveComponent("el-input")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["batchInput-container", [focused.value ? 'batchInput-container--focused' : '', _ctx.disabled ? 'batchInput-container--disabled' : '']]),
    style: _normalizeStyle({ borderColor: _ctx.borderColor })
  }, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(inputList.value, (item, index) => {
      return (_openBlock(), _createElementBlock("div", {
        key: `${item}${index}`,
        class: _normalizeClass([[(index + 1) % 2 === 0 ? 'batchInput__item--even' : ''], "pl-8 pr-16 pb-4 pt-4 flex-container-between batchInput__item"])
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_el_tooltip, {
            content: item,
            disabled: _unref(lte)(_unref(size)(item), 30)
          }, {
            default: _withCtx(() => [
              _createElementVNode("span", _hoisted_2, _toDisplayString(_unref(truncate)(item, { length: 30, separator: '...' })), 1)
            ]),
            _: 2
          }, 1032, ["content", "disabled"])
        ]),
        _createVNode(_unref(CloseOne), {
          class: "basic__HoverElement",
          style: {"color":"#d3d3d3"},
          onClick: ($event: any) => (deleteSelf(index))
        }, null, 8, ["onClick"])
      ], 2))
    }), 128)),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_el_input, {
        type: "textarea",
        modelValue: inputData.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((inputData).value = $event)),
        ref_key: "input",
        ref: input,
        style: {"max-width":"100%"},
        autosize: { maxRows: 9999, minRows: 3 },
        placeholder: _ctx.placeholder,
        disabled: _ctx.disabled,
        onKeydown: _withKeys(addItem, ["enter"]),
        onPaste: pasteData,
        onFocus: _cache[1] || (_cache[1] = ($event: any) => (focused.value = true)),
        onBlur: _cache[2] || (_cache[2] = 
          () => {
            focused.value = false;
            if (inputData.value) addItem();
          }
        ),
        clearable: ""
      }, null, 8, ["modelValue", "placeholder", "disabled"])
    ])
  ], 6))
}
}

})