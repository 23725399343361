const awbRoute = [
  {
    path: '/dcm/awb',
    component: () => import('@/layout/components/MicroApp/index.vue'),
    name: 'dcm.awb',
    props: true,
    meta: {
      clientAppRouteName: 'drs.clearanceOrder',
      clientApp: 'client'
    }
  },
  {
    path: '/dcm/awb/:id/edit',
    component: () => import('@/layout/components/MicroApp/index.vue'),
    name: 'dcm.awb.edit',
    props: true,
    meta: {
      clientAppRouteName: 'drs.clearanceOrder.edit',
      clientApp: 'client'
    }
  },
  {
    path: '/dcm/awb/:id/',
    component: () => import('@/layout/components/MicroApp/index.vue'),
    name: 'dcm.awb.view',
    props: true,
    meta: {
      clientAppRouteName: 'drs.clearanceOrder.view',
      clientApp: 'client'
    }
  }
];

const boxRoute = [
  {
    name: 'dcm.box',
    path: '/dcm/box',
    component: () => import('@/views/dcm/box/list/boxList.vue')
  },
  {
    name: 'dcm.box.view',
    path: '/dcm/box/:id',
    component: () => import('@/views/dcm/box/details/boxDetails.vue')
  }
];

const charterRoute = [
  {
    path: '/dcm/charter',
    component: () => import('@/views/dcm/charter/list/charterList.vue'),
    name: 'dcm.charter'
  },
  {
    path: '/dcm/charter/:id',
    component: () => import('@/views/dcm/charter/details/charterDetails.vue'),
    name: 'dcm.charter.view'
  }
];

const destockingRoute = [
  {
    path: '/dcm/destocking',
    component: () => import('@/views/dcm/destocking/list/destockingList.vue'),
    name: 'dcm.destocking'
  },
  {
    path: '/dcm/destocking/:id',
    component: () => import('@/views/dcm/destocking/details/destockingDetails.vue'),
    name: 'dcm.destocking.view'
  }
];

const forecastRoute = {
  path: '/dcm/forecast',
  component: () => import('@/layout/components/MicroApp/index.vue'),
  name: 'dcm.forecast',
  meta: {
    clientAppRouteName: 'drs.forecast',
    clientApp: 'client'
  }
};

const orderSearchRoute = {
  path: '/dcm/order/search',
  component: () => import('@/layout/components/MicroApp/index.vue'),
  name: 'dcm.orderSearch',
  meta: {
    clientAppRouteName: 'tsc.orderSearch',
    clientApp: 'client'
  }
};

const parcelRoute = [
  {
    name: 'dcm.parcel',
    path: '/dcm/parcel',
    component: () => import('@/views/dcm/parcel/list/parcelList.vue')
  },
  {
    name: 'dcm.parcel.view',
    path: '/dcm/parcel/:id',
    component: () => import('@/views/dcm/parcel/details/parcelDetails.vue'),
    props: true
  }
];

const transferFeeRoute = {
  path: '/dcm/transferFee',
  name: 'dcm.transferFee',
  component: () => import('@/views/dcm/transferFee/transferFeeList.vue')
};

const twbRoute = [
  {
    path: '/dcm/twb',
    component: () => import('@/layout/components/MicroApp/index.vue'),
    name: 'dcm.twb',
    meta: { clientAppRouteName: 'drs.twb', clientApp: 'client' }
  },
  {
    path: '/dcm/twb/:id',
    component: () => import('@/layout/components/MicroApp/index.vue'),
    name: 'dcm.twb.view',
    meta: { clientAppRouteName: 'drs.twb.view', clientApp: 'client' },
    props: true
  }
];

export default {
  name: 'dcm',
  component: () => import('@/layout/Layout.vue'),
  path: '/',
  children: [
    ...awbRoute,
    ...boxRoute,
    ...charterRoute,
    ...destockingRoute,
    ...twbRoute,
    forecastRoute,
    orderSearchRoute,
    ...parcelRoute,
    transferFeeRoute
  ]
};
