import { UseRegExpType } from '@/utils/type';

/**
 * 获取表单数据类型的正则
 * @returns {Object}
 * mawb MAWB单号的检验规则
 * number 数字类型的检验规则
 * email 邮件类型的检验规则
 * oneDecimal 一位小数的检验规则
 */
const useRegExp: UseRegExpType = () => ({
  mawb: {
    reg: /^[0-9]{3}-[0-9]{8}$/,
    is: (str) => /^[0-9]{3}-[0-9]{8}$/.test(str),
  },
  number: {
    reg: /^[0-9]+$/,
    is: (str) => /^[0-9]+$/.test(str),
  },
  email: {
    reg: /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/,
    is: (str) => /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(str),
  },
  oneDecimal: {
    reg: /^\d+(\.\d{1})?$/,
    is: (str) => /^\d+(\.\d{1})?$/.test(str),
  },
});

export { useRegExp };
