import { defineComponent as _defineComponent } from 'vue'
import { toDisplayString as _toDisplayString, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { onMounted, ref, Ref, onBeforeUnmount, watch, nextTick } from 'vue';
import { lt } from 'lodash-es';


export default /*@__PURE__*/_defineComponent({
  __name: 'HiddenWord',
  props: {
    content: {},
    position: { default: 'center' }
  },
  setup(__props: any) {

const props = __props;

const unHidden: Ref<boolean> = ref(true);
const container: Ref<any> = ref(null);
const observer: Ref<any> = ref(null);
const node: Ref<any> = ref(null);
const displayWidth: Ref<number> = ref(0);
const widthLimit: Ref<number> = ref(0);

const compareWidth = () => {
  const { width } = container.value.getBoundingClientRect();
  unHidden.value = lt(widthLimit.value, width);
  displayWidth.value = width;
};

watch(
  props,
  () => {
    nextTick(() => {
      ({ width: widthLimit.value } = node.value.getBoundingClientRect());
      compareWidth();
    });
  },
  {
    immediate: true
  }
);

onMounted(() => {
  observer.value = new ResizeObserver((entries: any[]) => {
    compareWidth();
  });
  observer.value.observe(container.value);
});

onBeforeUnmount(() => observer.value.disconnect());

return (_ctx: any,_cache: any) => {
  const _component_el_tooltip = _resolveComponent("el-tooltip")!

  return (_openBlock(), _createBlock(_component_el_tooltip, {
    disabled: unHidden.value,
    content: _ctx.content
  }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        ref_key: "container",
        ref: container,
        class: "basic-text__14",
        style: _normalizeStyle([{"width":"100%","position":"relative"}, { textAlign: _ctx.position }]),
        onResize: compareWidth
      }, [
        _createElementVNode("div", {
          class: "text--overflow",
          style: _normalizeStyle({ width: unHidden.value ? 'auto' : `${displayWidth.value}px`, position: 'absolute' }),
          ref_key: "node",
          ref: node
        }, _toDisplayString(_ctx.content), 5)
      ], 36)
    ]),
    _: 1
  }, 8, ["disabled", "content"]))
}
}

})