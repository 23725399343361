const messageRoute = {
  path: '/npm/message',
  component: () => import('@/views/npm/message/list/messageList.vue'),
  name: 'npm.message'
};

export default {
  name: 'npm',
  component: () => import('@/layout/Layout.vue'),
  path: '/',
  children: [messageRoute]
};
