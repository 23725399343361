const temuDeclareRuleRoute = {
  component: () => import('@/views/clr/temuDeclareRule/temuDeclareRuleList.vue'),
  path: '/clr/declareRule',
  name: 'clr.temuDeclareRule'
};

const inspectionRoute = [
  {
    component: () => import('@/views/clr/inspection/InspectionPage.vue'),
    name: 'clr.inspection',
    path: '/clr/inspection'
  }
];

const clearanceReturnPackageDeleteRoute = [
  {
    name: 'clr.clearanceReturnPackageDelete',
    path: '/clr/clearance/returnPackageDelete',
    component: () => import('@/views/clr/clearanceReturnPackageDelete/list/clearanceReturnPackageDelete.vue')
  },
  {
    name: 'clr.clearanceReturnPackageDelete.details',
    path: '/clr/clearance/returnPackageDelete/details/:id',
    component: () => import('@/views/clr/clearanceReturnPackageDelete/details/returnParcelDetail.vue')
  }
];

const clearanceAdjustRoute = {
  component: () => import('@/layout/components/MicroApp/index.vue'),
  name: 'clr.amendment.awb',
  path: '/clr/clearance/adjust',
  meta: {
    clientAppRouteName: 'clr.amendment.awb',
    clientApp: 'client'
  }
};

const clearanceSubmitRoute = {
  name: 'clr.clearance.submit',
  path: '/clr/clearance/submit',
  component: () => import('@/views/clr/clearanceManualSubmit/clearanceManualSubmit.vue')
};

const clearanceMonitorRoute = {
  component: () => import('@/views/clr/clearanceMonitor/clearanceMonitor.vue'),
  name: 'clr.clearanceMonitor',
  path: '/clr/clearance/monitor'
};

export default {
  name: 'clr',
  component: () => import('@/layout/Layout.vue'),
  path: '/',
  children: [
    temuDeclareRuleRoute,
    ...clearanceReturnPackageDeleteRoute,
    ...inspectionRoute,
    clearanceAdjustRoute,
    clearanceSubmitRoute,
    clearanceMonitorRoute
  ]
};
