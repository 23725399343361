import {
  dayjs,
  utc_cn as utcCN,
  utc_fr as utcFr,
  local_utc_date as localUtcDate,
  local_fr_date as localFrDate
} from '@ftlab/one-vue3/utils';

type formatSrc = Date | string | undefined;
type mapKeyType =
  | 'utcTime'
  | 'frTime'
  | 'localTime'
  | 'compact'
  | 'toUtc'
  | 'toFR'
  | 'cnTime'
  | 'toCN'
  | 'beginOfDayInUTC'
  | 'endOfDayInUTC'
  | 'frDate';
interface FormatFunc {
  (data: formatSrc): formatSrc;
}

const FORMAT = 'YYYY-MM-DD HH:mm:ss';
const DATE_FORMAT = 'YYYY-MM-DD';
/**
 * 展示utc时间(字符串)
 * @param date
 */
const utcTime: FormatFunc = (date) => (date ? (dayjs(date) as any).utc().format(FORMAT) : '--');
/**
 * utc时间转法国时间
 * @param date
 */
const frTime: FormatFunc = (date) => {
  if (date) {
    return utcFr(date);
  }
  return '--';
};

const frDate: FormatFunc = (date) => {
  if (date) {
    return (dayjs(date) as any).utc().tz('Europe/Paris').format(DATE_FORMAT);
  }
  return '--';
};
/**
 * 展示本地时间
 * @param date
 */
const localTime: FormatFunc = (date) => (date ? dayjs(date).format(FORMAT) : '--');
/**
 * 将时间转为utc格式(日期格式)
 * @param date
 */
const toUtc: FormatFunc = (date) => localUtcDate(date);
/**
 * 将中国时间转为法国日期，时间保持不变
 * @param date
 */
const toFR: FormatFunc = (date) => localFrDate(date);
/**
 * 将utc时间转为中国时间
 * @param date
 */
const toCN: FormatFunc = (date) => dayjs(toUtc(date)).subtract(8, 'h').toDate() || '--';
/**
 * 展示中国时间
 * @param date
 */
const cnTime: FormatFunc = (date) => {
  if (date) return utcCN(date);
  return '--';
};

const beginOfDayInUTC: FormatFunc = (date) => (date ? toUtc(dayjs(date).startOf('day').toDate()) : '');
const endOfDayInUTC: FormatFunc = (date) => (date ? toUtc(dayjs(date).endOf('day').toDate()) : '');

const formatterMap: Map<mapKeyType, FormatFunc> = new Map<mapKeyType, FormatFunc>([
  ['utcTime', utcTime],
  ['frTime', frTime],
  ['localTime', localTime],
  ['toUtc', toUtc],
  ['toFR', toFR],
  ['cnTime', cnTime],
  ['toCN', toCN],
  ['frDate', frDate],
  ['beginOfDayInUTC', beginOfDayInUTC],
  ['endOfDayInUTC', endOfDayInUTC]
]);

export default {
  format(key: mapKeyType, date: formatSrc): formatSrc {
    return (formatterMap.get(key) as FormatFunc)(date);
  }
};

export type { mapKeyType, formatSrc };
