// 功能依赖
import ElementPlus, { ElCollapseTransition } from 'element-plus';
import 'element-plus/theme-chalk/src/index.scss';
// @ts-expect-error 忽略该库的类型
import JsonViewer from 'vue3-json-viewer';
import 'vue3-json-viewer/dist/index.css';
// vue项目基本依赖
import { App as Vueapp, createApp } from 'vue';
import { createPinia } from 'pinia';
import FtlOne from '@ftlab/one-vue3';
import App from './App.vue';
import router from './router';
import { store, key } from './store';
// 项目基础设置
import actions from './action';
import './styles/app.scss';
import i18n from './i18n/index';
import formatter from './extends/formatter';
import install from '@/components/register';
// ftl-one
import '@ftlab/one-vue3/dist/style.css';

const app = createApp(App);
app.component(ElCollapseTransition.name as string, ElCollapseTransition);
app.config.compilerOptions.isCustomElement = (tag) => tag.includes('iconpark-');

app
  .use(router)
  .use(i18n)
  .use(formatter)
  .use(ElementPlus)
  .use(JsonViewer)
  .use(createPinia())
  .use(FtlOne as any)
  .use(store, key);

install(app);
app.mount('#app');
