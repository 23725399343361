import { utils, WorkBook, WorkSheet, writeFile } from 'xlsx';
import { saveAs } from 'file-saver';
import { last, split, includes, isEqual } from 'lodash-es';
import { resource } from '@/api';

/**
 * 导出XLSX文件
 * @param sheetData
 * @param fileName
 */
const exportXLSX = (sheetData: Array<any[]>, fileName: string) => {
  const workbook: WorkBook = utils.book_new();
  const sheet: WorkSheet = utils.aoa_to_sheet(sheetData);
  utils.book_append_sheet(workbook, sheet, 'Data');
  writeFile(workbook, `${fileName}.xlsx`);
};

/**
 * 通过url下载文件
 * @param url
 */
const downloadFileByURL = (url: string): void => {
  if (includes(url, 'pdf') || includes(url, 'PDF')) {
    window.open(url, last(split(url, '/')));
  } else {
    const a = document.createElement('a');
    a.href = url;
    a.download = last(split(url, '/')) || '';
    a.click();
  }
};

export { exportXLSX, downloadFileByURL };
