const agingDistributionRoute = {
  path: '/sim/agingDistribution',
  component: () => import('@/views/sim/timeDistribution/TimeDistributionPage.vue'),
  name: 'sim.agingDistribution'
};

const charterRoute = {
  path: '/sim/charter',
  component: () => import('@/views/sim/charterStats/CharterStatsPage.vue'),
  name: 'sim.charterFlight'
};

const clearanceRoute = {
  path: '/sim/clearance',
  component: () => import('@/views/sim/clearanceStats/ClearanceStatsPage.vue'),
  name: 'sim.clearanceFrance'
};

const forecastRoute = {
  path: '/sim/forecast',
  component: () => import('@/views/sim/forecastStats/ForecastStatsPage.vue'),
  name: 'sim.forecast'
};

const nextWeekInventoryForecastRoute = {
  path: '/sim/forecast/weekly',
  component: () => import('@/views/sim/cargoVolumeForecast/CargoVolumeForecastPage.vue'),
  name: 'sim.forecast.weekly'
};

const returnShipmentRoute = {
  path: '/sim/returnShipment',
  component: () => import('@/views/sim/returnShipmentStats/ReturnShipmentStatsPage.vue'),
  name: 'sim.returnShipment'
};

const saleRoute = {
  path: '/sim/sales',
  component: () => import('@/views/sim/salesStats/SalesStatsPage.vue'),
  name: 'sim.sales'
};

const shipmentRoute = {
  path: '/sim/shipment',
  component: () => import('@/views/sim/shipmentStats/ShipmentStatsPage.vue'),
  name: 'sim.shipment'
};

const warehouseRoute = {
  path: '/sim/warehouse',
  component: () => import('@/views/sim/warehouseStats/WarehouseStatsPage.vue'),
  name: 'sim.warehouse',
};

export default {
  name: 'sim',
  component: () => import('@/layout/Layout.vue'),
  path: '/',
  children: [
    agingDistributionRoute,
    charterRoute,
    clearanceRoute,
    forecastRoute,
    nextWeekInventoryForecastRoute,
    returnShipmentRoute,
    saleRoute,
    shipmentRoute,
    warehouseRoute
  ]
};
