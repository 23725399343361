import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, mergeProps as _mergeProps, withCtx as _withCtx, createBlock as _createBlock, unref as _unref, createVNode as _createVNode, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, withKeys as _withKeys } from "vue"

const _hoisted_1 = { class: "flex-container-start" }

import { Ref, ref, watch } from 'vue';
import { compact, includes } from 'lodash-es';
import { Plus as PlusIcon } from '@icon-park/vue-next';

interface EditTagsProps {
  modelValue: string[]; // 绑定编辑值
  buttonText?: string; // 按钮文本
}

export default /*@__PURE__*/_defineComponent({
  __name: 'EditTags',
  props: {
    modelValue: {},
    buttonText: { default: 'Add Tag' }
  },
  emits: ['update:modelValue'],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emits = __emit;

const edit: Ref<boolean> = ref(false);
const tags: Ref<string[]> = ref([]);
const tagInput: Ref<string> = ref('');

const addTag = () => {
  if (!includes(tags.value, tagInput.value)) tags.value.push(tagInput.value);
  tagInput.value = '';
  edit.value = false;
  emits('update:modelValue', tags.value);
};

const handleClose = (index: number) => {
  tags.value.splice(index, 1);
  emits('update:modelValue', tags.value);
};

watch(
  props,
  (value) => {
    tags.value = compact(value.modelValue);
    tagInput.value = '';
    edit.value = false;
  },
  { immediate: true }
);

return (_ctx: any,_cache: any) => {
  const _component_el_tag = _resolveComponent("el-tag")!
  const _component_el_button = _resolveComponent("el-button")!
  const _component_el_input = _resolveComponent("el-input")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(tags.value, (item, index) => {
      return (_openBlock(), _createBlock(_component_el_tag, _mergeProps({ ref_for: true }, _ctx.$attrs, {
        key: item,
        round: "",
        style: {"margin-left":"0","margin-right":"8px"},
        closable: "",
        class: "mb-8",
        onClose: () => handleClose(index)
      }), {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(item), 1)
        ]),
        _: 2
      }, 1040, ["onClose"]))
    }), 128)),
    (!edit.value)
      ? (_openBlock(), _createBlock(_component_el_button, {
          key: 0,
          onClick: _cache[0] || (_cache[0] = ($event: any) => (edit.value = true)),
          size: "small",
          class: "mb-8"
        }, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createVNode(_unref(PlusIcon), { class: "icon-margin" }),
              _createTextVNode(" " + _toDisplayString(_ctx.buttonText), 1)
            ])
          ]),
          _: 1
        }))
      : (_openBlock(), _createBlock(_component_el_input, {
          key: 1,
          modelValue: tagInput.value,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((tagInput).value = $event)),
          onKeydown: _withKeys(addTag, ["enter"]),
          size: "small",
          class: "mb-8",
          style: {"width":"60px"}
        }, null, 8, ["modelValue"]))
  ], 64))
}
}

})