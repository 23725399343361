const accountConfigRoute = {
  name: 'usm.account',
  component: () => import('@/views/usm/accountManage/accountManageList.vue'),
  path: '/usm/account'
};

export default {
  name: 'usm',
  component: () => import('@/layout/Layout.vue'),
  path: '/',
  children: [ accountConfigRoute]
};
