import aviation from './psm_aviation.json';
import transferProductSetting from './psm_transferProductSetting.json';
import shipmentProductSetting from './psm_shipmentProductSetting.json';
import clearanceProductSetting from './psm_clearanceProductSetting.json';

export default {
  ...aviation,
  ...transferProductSetting,
  ...shipmentProductSetting,
  ...clearanceProductSetting
};
