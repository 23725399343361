import { ref, Ref } from 'vue';
import { isObject, sortBy, uniqBy } from 'lodash-es';
import type { selectOption, resBody } from '@/components/FtlSelect/type';
import { warning } from '@/utils';
import OptionsResource from '@/api/OptionsResource';
import i18n, { globalTranslate } from '@/i18n';

const statusText = ref('');
const error = ref(false);
const retryTimes = ref(0);
const client: Ref<string> = ref('');
const mode: Ref<string> = ref('');
const priceType: Ref<string> = ref('');

const makeRequest: selectOption = async ({ labelKey, valueKey, query, translateKey, formatter, request }) => {
  if (error.value) retryTimes.value += 1;
  if (retryTimes.value > 5) warning('global.error.network');
  error.value = false;
  try {
    statusText.value = 'Loading...';
    const response = await request(query);
    const data = response || [];
    const result = data.map((val: Record<string, string> | string) =>
      isObject(val)
        ? {
            label: translateKey
              ? globalTranslate(`${translateKey}.${val[labelKey]}`)
              : formatter
              ? formatter((val as Record<string, string>)[labelKey], (val as Record<string, string>)[valueKey])
              : (val as Record<string, string>)[labelKey],
            value: (val as Record<string, string>)[valueKey]
          }
        : {
            label: translateKey ? globalTranslate(`${translateKey}.${val}`) : (val as string),
            value: val as string
          }
    );

    const sortedResult = isObject(data[0])
      ? sortBy(result, 'label')
      : result.sort((a: { label: string }, b: { label: string }) => a.label.localeCompare(b.label));
    retryTimes.value = 0;
    return uniqBy(sortedResult, 'value') as any;
  } catch (e) {
    statusText.value = 'Loading Error';
    error.value = true;
    return [];
  } finally {
    statusText.value = '暂无数据';
  }
};

const requestClientInfo = () =>
  makeRequest({
    request: OptionsResource.getClientOptions,
    labelKey: 'name',
    valueKey: 'id',
    formatter: (label: string, value: string) => `${value}-${label}`
  });

const requestTransferLine = (params: any) =>
  makeRequest({
    request: () => OptionsResource.getTransferProducts(params),
    labelKey: 'name',
    valueKey: 'name',
    query: params
  });

const requestTransferSuppliers = () =>
  makeRequest({
    request: () => OptionsResource.getTransferProducts({ filter: { shipment: false, client: client.value } }),
    labelKey: 'supplier',
    valueKey: 'supplier'
  });

const requestTransferClientCode = () =>
  makeRequest({
    request: OptionsResource.getTransferProductMappings,
    labelKey: 'alias',
    valueKey: 'alias'
  });

const requestSenderConfig = () =>
  makeRequest({
    request: OptionsResource.getShipmentSenderAddresses,
    labelKey: 'id',
    valueKey: 'id'
  });

const requestChannel = () =>
  makeRequest({
    request: () => OptionsResource.getTransferProductChannels({ option: mode.value }),
    labelKey: 'id',
    valueKey: 'id'
  });

const requestBlackListName = () =>
  makeRequest({
    request: OptionsResource.getBlackListName,
    labelKey: 'ruleName',
    valueKey: 'ruleName'
  });

const requestShipmentLine = () =>
  makeRequest({
    request: () =>
      OptionsResource.getPriceRoutes({
        type: 'SHIPMENT',
        client: client.value,
        ...(mode.value ? { mode: mode.value } : {})
      }),
    labelKey: 'id',
    valueKey: 'id'
  });

const requestPriceLevel = () =>
  makeRequest({
    request: () => OptionsResource.getPriceGradeNames(priceType.value),
    labelKey: 'priceGrade',
    valueKey: 'id'
  });

const requestClearanceType = (params: any) =>
  makeRequest({
    request: () => OptionsResource.getClearanceType(params),
    labelKey: 'id',
    valueKey: 'id',
    query: params
  });

const requestClearanceCountry = () =>
  makeRequest({
    request: OptionsResource.getClearanceCountries,
    labelKey: 'countryCode',
    valueKey: 'countryCode'
  });

const setClient = (props: string): void => {
  client.value = props;
};
const setMode = (props: string): void => {
  mode.value = props;
};

const setPriceType = (props: string): void => {
  priceType.value = props;
};

export {
  requestClientInfo,
  requestTransferLine,
  requestTransferSuppliers,
  requestTransferClientCode,
  requestShipmentLine,
  requestSenderConfig,
  requestChannel,
  requestBlackListName,
  requestPriceLevel,
  requestClearanceType,
  requestClearanceCountry,
  statusText,
  error,
  setClient,
  setPriceType,
  setMode
};
