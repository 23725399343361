// 预报设置路由
const forecastConfigRoute = {
  path: '/csm/forecastSetting',
  component: () => import('@/layout/components/MicroApp/index.vue'),
  name: 'csm.forecastSetting',
  meta: {
    clientAppRouteName: 'csm.forecastSetting',
    clientApp: 'client'
  }
};

// 发货人设置路由
const shipperConfigRoute = {
  path: '/csm/shipperSetting',
  component: () => import('@/layout/components/MicroApp/index.vue'),
  name: 'csm.shipperSetting',
  meta: {
    clientAppRouteName: 'csm.shipperSetting',
    clientApp: 'client'
  }
};

// 邮件设置路由
const emailRoute = [
  {
    component: () => import('@/layout/components/MicroApp/index.vue'),
    name: 'csm.email.client',
    path: '/csm/email/subscription/:client',
    meta: {
      clientAppRouteName: 'csm.email.subscription.client',
      clientApp: 'client'
    }
  },
  {
    component: () => import('@/views/csm/email/emailList.vue'),
    name: 'csm.email',
    path: '/csm/email'
  }
];

export default {
  name: 'csm',
  component: () => import('@/layout/Layout.vue'),
  path: '/',
  children: [forecastConfigRoute, shipperConfigRoute, ...emailRoute]
};
