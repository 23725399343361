import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, normalizeStyle as _normalizeStyle, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { style: {"border":"1px solid #ccc"} }

import '@wangeditor/editor/dist/css/style.css';
import { onBeforeUnmount, ref, Ref, shallowRef, watch, onMounted } from 'vue';
import { useI18n } from 'vue-i18n';
import { Toolbar, Editor } from '@wangeditor/editor-for-vue';
import { IToolbarConfig } from '@wangeditor/editor';
import { cloneDeep, isEmpty } from 'lodash-es';
import { ToolBarKeyType } from '@/components/type';

interface EditorPropsType {
  readonly excludeKeys: ToolBarKeyType[]; // 富文本编辑器工具栏资源配置
  readonly editorHeight: number; // 富文本编辑器高度
  modelValue: any; // 编辑器内容绑定
}


export default /*@__PURE__*/_defineComponent({
  __name: 'RtfEditor',
  props: {
    excludeKeys: {},
    editorHeight: { default: 200 },
    modelValue: {}
  },
  emits: ['update:modelValue'],
  setup(__props: any, { emit: __emit }) {

const props = __props;
const emits = __emit;
const i18n = useI18n({ useScope: 'global' });
const editorRef = shallowRef();
const valueHtml: Ref<string> = ref(!isEmpty(props.modelValue) ? cloneDeep(props.modelValue) : '<p></p>');
const toolBarConfig: Partial<IToolbarConfig> = {
  excludeKeys: props.excludeKeys
};

const editorConfig = { placeholder: `${i18n.t('placeholder.input')}...` };

const handleCreated = (editor: any) => {
  editorRef.value = editor;
};

watch(valueHtml, (val) => {
  emits('update:modelValue', val);
});

onBeforeUnmount(() => {
  const editor = editorRef.value;
  if (editor == null) return;
  editor.destroy();
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_unref(Toolbar), {
      style: {"border-bottom":"1px solid #ccc"},
      editor: editorRef.value,
      defaultConfig: toolBarConfig,
      mode: "default"
    }, null, 8, ["editor"]),
    _createVNode(_unref(Editor), {
      style: _normalizeStyle({ height: `${_ctx.editorHeight}px`, overflow: 'hidden' }),
      modelValue: valueHtml.value,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((valueHtml).value = $event)),
      defaultConfig: editorConfig,
      mode: "default",
      onOnCreated: handleCreated
    }, null, 8, ["style", "modelValue"])
  ]))
}
}

})