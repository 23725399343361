const b2bServiceRoute = [
  {
    path: '/oms/b2bService',
    component: () => import('@/layout/components/MicroApp/index.vue'),
    name: 'oms.b2bService',
    meta: {
      clientAppRouteName: 'oms.b2bService',
      clientApp: 'client'
    }
  },
  {
    path: '/oms/b2bService/view/:id',
    component: () => import('@/layout/components/MicroApp/index.vue'),
    name: 'oms.b2bService.view',
    meta: {
      clientAppRouteName: 'oms.b2bService.view',
      clientApp: 'client'
    },
    props: true
  },
  {
    path: '/oms/b2bService/create',
    component: () => import('@/layout/components/MicroApp/index.vue'),
    name: 'oms.b2bService.create',
    meta: {
      clientAppRouteName: 'oms.b2bService.create',
      clientApp: 'client'
    }
  },
  {
    path: '/oms/b2bService/edit/:id?/:edit?',
    component: () => import('@/layout/components/MicroApp/index.vue'),
    name: 'oms.b2bService.edit',
    meta: {
      clientAppRouteName: 'oms.b2bService.edit',
      clientApp: 'client'
    },
    props: true
  }
];

const clearanceServiceRoute = [
  {
    path: '/oms/clearanceService/add_twb',
    component: () => import('@/layout/components/MicroApp/index.vue'),
    name: 'oms.clearanceService.command',
    meta: {
      clientAppRouteName: 'oms.clearanceService.command',
      clientApp: 'client'
    }
  },
  {
    path: '/oms/clearanceService/create',
    component: () => import('@/layout/components/MicroApp/index.vue'),
    name: 'oms.clearanceService.create',
    meta: {
      clientAppRouteName: 'oms.clearanceService.create',
      clientApp: 'client'
    }
  },
  {
    path: '/oms/clearanceService/submit',
    component: () => import('@/layout/components/MicroApp/index.vue'),
    name: 'oms.clearanceService.confirm',
    meta: {
      clientAppRouteName: 'oms.clearanceService.confirm',
      clientApp: 'client'
    }
  },
  {
    path: '/oms/clearanceService',
    component: () => import('@/layout/components/MicroApp/index.vue'),
    name: 'oms.clearanceService',
    meta: {
      clientAppRouteName: 'oms.clearanceService',
      clientApp: 'client'
    }
  },
  {
    path: '/oms/clearanceService/choose',
    component: () => import('@/layout/components/MicroApp/index.vue'),
    name: 'oms.clearanceService.choose',
    meta: {
      clientAppRouteName: 'oms.clearanceService.choose',
      clientApp: 'client'
    }
  }
];

const noClearanceRoute = [
  {
    path: '/oms/noClearanceService',
    component: () => import('@/layout/components/MicroApp/index.vue'),
    name: 'oms.noClearanceService.new',
    meta: {
      clientAppRouteName: 'oms.noClearanceService.new',
      clientApp: 'client'
    }
  },
  {
    path: '/oms/noClearanceService/create',
    component: () => import('@/layout/components/MicroApp/index.vue'),
    name: 'oms.noClearanceService.new.create',
    meta: {
      clientAppRouteName: 'oms.noClearanceService.new.create',
      clientApp: 'client'
    }
  },
  {
    path: '/oms/noClearanceService/twb',
    component: () => import('@/layout/components/MicroApp/index.vue'),
    name: 'oms.noClearanceService.new.command',
    meta: {
      clientAppRouteName: 'oms.noClearanceService.new.command',
      clientApp: 'client'
    }
  },
  {
    path: '/oms/noClearanceService/confirm',
    component: () => import('@/layout/components/MicroApp/index.vue'),
    name: 'oms.noClearanceService.new.confirm',
    meta: {
      clientAppRouteName: 'oms.noClearanceService.new.confirm',
      clientApp: 'client'
    }
  }
];

const t1ServiceRoute = [
  {
    path: '/oms/t1',
    component: () => import('@/layout/components/MicroApp/index.vue'),
    name: 'oms.t1',
    meta: {
      clientAppRouteName: 'oms.t1',
      clientApp: 'client'
    }
  },
  {
    path: '/oms/t1/create',
    component: () => import('@/layout/components/MicroApp/index.vue'),
    name: 'oms.t1.create',
    meta: {
      clientAppRouteName: 'oms.t1.create',
      clientApp: 'client'
    }
  },
  {
    path: '/oms/t1/edit',
    component: () => import('@/layout/components/MicroApp/index.vue'),
    name: 'oms.t1.edit',
    meta: {
      clientAppRouteName: 'oms.t1.edit',
      clientApp: 'client'
    }
  },
  {
    path: '/oms/t1/view',
    component: () => import('@/layout/components/MicroApp/index.vue'),
    name: 'oms.t1.view',
    meta: {
      clientAppRouteName: 'oms.t1.view',
      clientApp: 'client'
    }
  },
  {
    path: '/oms/t1/confirm',
    component: () => import('@/layout/components/MicroApp/index.vue'),
    name: 'oms.t1.confirm',
    meta: {
      clientAppRouteName: 'oms.t1.confirm',
      clientApp: 'client'
    }
  }
];

export default {
  name: 'oms',
  component: () => import('@/layout/Layout.vue'),
  path: '/',
  children: [...clearanceServiceRoute, ...b2bServiceRoute, ...noClearanceRoute, ...t1ServiceRoute]
};
