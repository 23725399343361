import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withKeys as _withKeys, normalizeStyle as _normalizeStyle } from "vue"

import { onBeforeUnmount, onMounted, provide, ref } from 'vue';
import { debounce } from 'lodash-es';
import type { Ref } from 'vue';

interface FtlContainerPropsType {
  noPadding: boolean;
  top: number;
  noHeader?: boolean;
  card?: boolean;
  autoResize?: boolean;
  headerMargin?: boolean; // 新增 headerMargin 属性
}


export default /*@__PURE__*/_defineComponent({
  __name: 'FtlContainer',
  props: {
    noPadding: { type: Boolean, default: false },
    top: { default: 0 },
    noHeader: { type: Boolean, default: false },
    card: { type: Boolean, default: true },
    autoResize: { type: Boolean, default: true },
    headerMargin: { type: Boolean, default: true }
  },
  emits: ['refresh'],
  setup(__props: any, { emit: __emit }) {

const mainHeight = ref<number>(0);
const container: Ref<any> = ref(null);
const props = __props;
const emits = __emit;
const sizeObserver = new ResizeObserver(
  debounce((arr) => {
    const { contentRect } = arr[0];
    if (props.autoResize) mainHeight.value = contentRect.height || 0;
  }, 100)
);

onMounted(() => {
  sizeObserver.observe(container.value as Element);
});
onBeforeUnmount(() => sizeObserver.disconnect());
provide('tableMaxHeight', mainHeight);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["ftl-container", { 'ftl-container-card': _ctx.card }]),
    style: _normalizeStyle({ height: !_ctx.autoResize ? 'auto' : `calc(100vh - ${88 + _ctx.top}px)` }),
    tabindex: "-1",
    onKeydown: _cache[0] || (_cache[0] = _withKeys(($event: any) => (_ctx.$emit('refresh')), ["enter"]))
  }, [
    (!_ctx.noHeader)
      ? (_openBlock(), _createElementBlock("header", {
          key: 0,
          class: _normalizeClass({ 'mb-16': _ctx.headerMargin })
        }, [
          _renderSlot(_ctx.$slots, "header")
        ], 2))
      : _createCommentVNode("", true),
    _createElementVNode("main", {
      ref_key: "container",
      ref: container,
      style: {"flex":"1","z-index":"1","overflow":"hidden"}
    }, [
      _renderSlot(_ctx.$slots, "container", {
        height: mainHeight.value - 2
      })
    ], 512)
  ], 38))
}
}

})