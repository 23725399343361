const dashboardRoute = {
  component: () => import('@/views/sts/dashboard/DashboardPage.vue'),
  name: 'sts.dashboard',
  path: '/sts/dashboard'
};

export default {
  name: 'sts',
  component: () => import('@/layout/Layout.vue'),
  path: '/',
  redirect: { name: 'sts.dashboard' },
  children: [dashboardRoute]
};
