import { createStore, Store, useStore as baseUseStore } from 'vuex';
import { InjectionKey } from 'vue';
import createPersistedState from 'vuex-persistedstate';
import authStore, { AuthStore } from '@/store/Auth';

export interface State {
  token: string;
  environment: string;
  adminToken: string;
  auth?: AuthStore;
}

export const key: InjectionKey<Store<State>> = Symbol();

export const store = createStore<State>({
  state: {
    token: '',
    environment: 'PROD',
    adminToken: '',
  },
  mutations: {
    setToken(state, token) {
      state.token = token;
    },
    setEnvironment(state, environment) {
      state.environment = environment;
    },
    setAdminToken(state, token) {
      state.adminToken = token;
    },
  },
  actions: {},
  modules: {
    auth: authStore,
  },
  plugins: [createPersistedState()],
});

export function useStore(): Store<State> {
  return baseUseStore(key);
}
