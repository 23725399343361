import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, mergeProps as _mergeProps, withCtx as _withCtx, normalizeStyle as _normalizeStyle, normalizeClass as _normalizeClass, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { ref, computed, watch, Ref } from 'vue';
import { isEqual } from 'lodash-es';

type tableData = Array<Record<string, any>>;

interface Props {
  src: tableData; // 数据来源
  loading: boolean; // 加载状态
  layout?: string;
  totalElements: number; // 全部元素数量
  page: number; // 页数
  emptyText?: string; // 暂无内容的秒速文字
  maxHeight: number; // 最大高度
  highlightCurrentRow?: boolean; //是否高亮
  size?: number;
}

// eslint-disable-next-line no-undef

export default /*@__PURE__*/_defineComponent({
  __name: 'FtlTable',
  props: {
    src: {},
    loading: { type: Boolean, default: false },
    layout: { default: 'total,sizes,prev,pager, next, jumper' },
    totalElements: {},
    page: {},
    emptyText: {},
    maxHeight: { default: 10000 },
    highlightCurrentRow: { type: Boolean },
    size: {}
  },
  emits: ['refresh', 'sort', 'rowClick'],
  setup(__props: any, { expose: __expose, emit: __emit }) {

const props = __props;
const ftlTableRef = ref();
const emits = __emit;
const tableSrc = computed(() => props.src);
const pageNum: Ref<number> = ref(0);
const pageSize: Ref<number> = ref(20);
const selected: Ref<Array<Record<string, unknown>>> = ref([]);
const changePageNum = (val: number) => {
  emits('refresh', val - 1);
};
const changePageSize = (val: number) => {
  emits('refresh', 0, val);
};
const sortChange = ({ order, prop }: Record<string, string>) => {
  emits('sort', order ? `${prop},${isEqual(order, 'descending') ? 'desc' : 'asc'}` : undefined);
};
const handleRowClick = (row: any) => {
  emits('rowClick', row);
};
const setCurrentRow = (row: any) => {
  ftlTableRef.value?.setCurrentRow(row);
};
const noPaginationDisplay = computed(() => {
  return props.totalElements <= 10 || !props.totalElements;
});

const tableMaxHeight = computed(() => (noPaginationDisplay.value ? props.maxHeight + 48 : props.maxHeight + 12));

watch(props, (val) => {
  pageNum.value = val.page === 0 ? 1 : val.page + 1;
  pageSize.value = val.size ?? 20;
});

__expose({
  getSelected: () => selected.value,
  setCurrentRow
});

return (_ctx: any,_cache: any) => {
  const _component_el_empty = _resolveComponent("el-empty")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_el_scrollbar = _resolveComponent("el-scrollbar")!
  const _component_el_skeleton = _resolveComponent("el-skeleton")!
  const _component_el_pagination = _resolveComponent("el-pagination")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_el_skeleton, {
      animated: "",
      loading: _ctx.loading,
      rows: 6,
      style: _normalizeStyle({ height: `${tableMaxHeight.value}px` })
    }, {
      default: _withCtx(() => [
        _createElementVNode("main", null, [
          _createVNode(_component_el_scrollbar, null, {
            default: _withCtx(() => [
              _createVNode(_component_el_table, _mergeProps({
                ref_key: "ftlTableRef",
                ref: ftlTableRef,
                data: tableSrc.value,
                "header-cell-style": 
            () => ({
              color: '#797678',
              borderLeft: 'solid 1px #ebeef5'
            })
          ,
                "cell-style": () => ({ borderLeft: 'solid 1px #ebeef5' }),
                border: "",
                "max-height": `${tableMaxHeight.value}px`,
                "highlight-current-row": _ctx.highlightCurrentRow,
                onSortChange: sortChange,
                onRowClick: handleRowClick,
                onSelectionChange: _cache[0] || (_cache[0] = (val) => (selected.value = val)),
                style: { height: `${tableMaxHeight.value}px` }
              }, _ctx.$attrs), {
                empty: _withCtx(() => [
                  _createVNode(_component_el_empty, {
                    description: _ctx.$t(_ctx.emptyText || 'dcm.box.support.noData')
                  }, null, 8, ["description"])
                ]),
                append: _withCtx(() => [
                  _renderSlot(_ctx.$slots, "append", {}, () => [
                    _cache[3] || (_cache[3] = _createElementVNode("div", { class: "flex-1" }, null, -1))
                  ])
                ]),
                default: _withCtx(() => [
                  _renderSlot(_ctx.$slots, "default")
                ]),
                _: 3
              }, 16, ["data", "max-height", "highlight-current-row", "style"])
            ]),
            _: 3
          })
        ])
      ]),
      _: 3
    }, 8, ["loading", "style"]),
    _createElementVNode("footer", {
      class: _normalizeClass({ 'mt-8': !noPaginationDisplay.value })
    }, [
      _createVNode(_component_el_pagination, {
        small: "",
        "current-page": pageNum.value,
        "onUpdate:currentPage": _cache[1] || (_cache[1] = ($event: any) => ((pageNum).value = $event)),
        "page-size": pageSize.value,
        "onUpdate:pageSize": _cache[2] || (_cache[2] = ($event: any) => ((pageSize).value = $event)),
        "hide-on-single-page": noPaginationDisplay.value,
        "page-sizes": [10, 20, 50, 100],
        "pager-count": 11,
        onCurrentChange: changePageNum,
        onSizeChange: changePageSize,
        layout: _ctx.layout,
        total: _ctx.totalElements
      }, null, 8, ["current-page", "page-size", "hide-on-single-page", "layout", "total"])
    ], 2)
  ], 64))
}
}

})