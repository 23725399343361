const workOrderReplyRoute = {
  name: 'fam.workOrderReply',
  component: () => import('@/views/fam/WorkOrderReply/WorkOrderReplyPage.vue'),
  path: '/fam/workOrderReply'
};

const parcelOverdueHandoverRoute = {
  path: '/fam/parcelOverdueHandover',
  name: 'fam.parcelOverdueHandover',
  component: () => import('@/views/fam/ParcelOverdueHandover/ParcelOverdueHandoverPage.vue')
};

const parcelPenaltyAppealRoute = {
  path: '/fam/parcelPenaltyAppeal',
  name: 'fam.parcelPenaltyAppeal',
  component: () => import('@/views/fam/ParcelPenaltyAppeal/ParcelPenaltyAppealPage.vue')
};

export default {
  name: 'fam',
  component: () => import('@/layout/Layout.vue'),
  path: '/',
  children: [workOrderReplyRoute, parcelOverdueHandoverRoute, parcelPenaltyAppealRoute]
};
