import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

import { computed } from 'vue';
import { isEmpty, nth } from 'lodash-es';
import { useTheme } from '@/hooks/view/ui/theme';
import { ThemeKeywordsType } from '@/hooks/view/type';


export default /*@__PURE__*/_defineComponent({
  __name: 'FtlTag',
  props: {
    text: {}
  },
  setup(__props: any) {

const props = __props;

const theme = computed(() => useTheme(props.text));

return (_ctx: any,_cache: any) => {
  const _component_el_tag = _resolveComponent("el-tag")!

  return (!_unref(isEmpty)(_ctx.text))
    ? (_openBlock(), _createBlock(_component_el_tag, {
        key: 0,
        round: "",
        style: _normalizeStyle([{"margin-left":"0"}, { color: _unref(nth)(theme.value, 2), background: _unref(nth)(theme.value, 1), borderColor: _unref(nth)(theme.value, 0) }]),
        class: "mr-8"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.text), 1)
        ]),
        _: 1
      }, 8, ["style"]))
    : _createCommentVNode("", true)
}
}

})