const billRoute = [
  {
    path: '/bms/bill',
    component: () => import('@/views/bms/financialFlow/FinancialFlowPage.vue'),
    name: 'bms.bill'
  }
];

const auditRoute = {
  component: () => import('@/views/bms/audit/AuditPage.vue'),
  name: 'bms.audit',
  path: '/bms/audit'
};

export default {
  name: 'bms',
  component: () => import('@/layout/Layout.vue'),
  path: '/',
  children: [...billRoute, auditRoute]
};
