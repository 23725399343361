import { isEqual, get, keys, isUndefined } from 'lodash-es';
import { error } from '@/utils';

export type UserInfoType = {
  username: string;
  group: string;
  language: string;
  type: string;
  sub: string;
};

export interface AuthStore {
  userInfo: UserInfoType;
  clientList: Array<Record<string, unknown>>;
  toggled: boolean;
  adminInfo: UserInfoType;
}

const createUser = (): UserInfoType => ({
  username: '',
  group: '',
  language: '',
  type: '',
  sub: ''
});

const authStore = {
  state: {
    userInfo: createUser(),
    clientList: [],
    toggled: false,
    adminInfo: createUser()
  },
  getters: {
    isAdmin(state: AuthStore) {
      return isEqual(get(state, 'userInfo.type'), 'ROOT') || isEqual(get(state, 'userInfo.type'), 'ADMIN');
    }
  },
  mutations: {
    registerAdminUser(state: AuthStore, userInfo: UserInfoType) {
      if (isEqual(keys(userInfo), ['username', 'group', 'language', 'type', 'sub'])) state.adminInfo = userInfo;
      else error('用户信息注册错误');
    },
    registerUser(state: AuthStore, userInfo: UserInfoType) {
      if (isEqual(keys(userInfo), ['username', 'group', 'language', 'type', 'sub'])) state.userInfo = userInfo;
      else error('用户信息注册错误');
    },
    initClientList(state: AuthStore, list: Array<Record<string, unknown>>) {
      state.clientList = list;
    },
    toggleUser(state: AuthStore, init: boolean) {
      state.toggled = isUndefined(init) ? !state.toggled : init;
    }
  }
};

export default authStore;
